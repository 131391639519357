.button{
  &.button-primary{
    color: #FDBB30;
    background: #000000;
    box-shadow: #324832 0 2px 0 0;
    padding: 10px 16px 8px 16px;
    border-radius: 6px;
    text-decoration: none;
    &:hover{
      background: #ab82b8;
    }
  }
}